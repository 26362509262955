<template>
    <div>
      <!-- <base-img
        :src="require('@/assets/logo-orion-new.jpeg')"
        contain
        max-width="150"
        class="ml-n6"
      /> -->

      <base-body>
        <b class="white--text">Orion Penjaminan Indonesia</b>
      </base-body>
      <!-- <div id="google_translate_element"></div> -->
    </div>
</template>
<script>
  export default {
    name: 'BaseInfo',

    props: {
      title: String,
      dark: Boolean,
    },

    data: () => ({
      business: [
        {
          icon: 'mdi-map-marker-outline',
          title: 'Address',
          text: '8553 N. Beach St. Ste. 227<br>Fort Worth, Texas 76137',
        },
        {
          icon: 'mdi-cellphone',
          title: 'Phone',
          text: '01 (800) 433 744<br>01 (800) 433 633',
        },
        {
          icon: 'mdi-email',
          title: 'Email',
          text: 'john@vuetifyjs.com<br>heather@vuetifyjs.com',
        },
      ],
    }),
  }
</script>
