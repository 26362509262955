<template>
  <v-fade-transition mode="out-in">
    <router-view/>
  </v-fade-transition>
</template>

<script>
  export default {
    name: 'App',
    metaInfo: {
      title: 'PenjaminanAman',
      titleTemplate: '%s | Orion Penjaminan Indonesia',
      htmlAttrs: { lang: 'en' },
      meta: [
        { charset: 'utf-8' },
        { name: 'viewport', content: 'width=device-width, initial-scale=1' },
        {property: 'og:title', content: 'Orion Penjaminan Indonesia - PenjaminanAman'},
        {property: 'og:site_name', content: 'Orion Penjaminan Indonesia'},
        // The list of types is available here: http://ogp.me/#types
        {property: 'og:type', content: 'website'},
        // Should the the same as your canonical link, see below.
        {property: 'og:url', content: 'https://www.orionpenjaminan.co.id/my-special-page'},
        {property: 'og:image', content: 'https://www.orionpenjaminan.co.id/my-special-image.jpg'},
        // Often the same as your meta description, but not always.
        {property: 'og:description', content: 'Penjaminan Aman'},

        // Twitter card
        {name: 'twitter:card', content: 'summary'},
        {name: 'twitter:site', content: 'https://www.orionpenjaminan.co.id/my-special-page'},
        {name: 'twitter:title', content: 'My Page Title ← My Site'},
        {name: 'twitter:description', content: 'Penjaminan Aman'},
        // Your twitter handle, if you have one.
        {name: 'twitter:creator', content: '@alligatorio'},
        {name: 'twitter:image:src', content: 'https://www.orionpenjaminan.co.id/my-special-image.jpg'},

        // Google / Schema.org markup:
        {itemprop: 'name', content: 'Orion Penjaminan Indonesia - PenjaminanAman'},
        {itemprop: 'description', content: 'Penjaminan Aman'},
        {itemprop: 'image', content: 'https://www.orionpenjaminan.co.id/my-special-image.jpg'}
      ],
      link: [
        {rel: 'canonical', href: 'https://www.orionpenjaminan.co.id/my-special-page'}
      ],
    },
  }
</script>

<style lang="sass">
  .extra-padding
    padding-bottom: 96px !important
    padding-top: 96px !important

    @media screen and (max-width: 959px)
      padding-top: 48px !important
      padding-bottom: 48px !important
</style>
