<template>
  <v-theme-provider :dark="dark">
    <div>
      <base-info-card
        :title="title"
        pace="6"
      >
        <div class="text-justify">{{ text }}</div>
      </base-info-card>

    </div>
  </v-theme-provider>
</template>

<script>
  export default {
    name: 'BaseBusinessInfo',

    props: {
      dark: Boolean,
      title: String,
      text: String,
    },
  }
</script>
